<template>
  <div class="col-md-12">
    <div class="card card-info card-outline ">
      <div class="card-header pt-2 pb-2">
        <div class="row justify-content-between">
          <h3 class="card-title">
            <i class="fa fa-file-signature text-navy"></i> Asignación Cargos Contables <small>(Fijos)</small>
          </h3>
          <div>
            <v-select class="form-control form-control-sm p-0"
              style="min-width: 30em;"
              v-model="asignacionContable"
              placeholder="Asignación Contable"
              label="nombre"
              :options="listasForms.asignaciones_contables"
              @input="cambiarAsignaciones"
              ></v-select>
          </div>
          <div class="card-tools">
            <button
              type="button"
              class="btn bg-frontera text-white"
              @click="addContable()"
              :disabled="estado_contable == 1 || totalPresupuesto == 100"
            >
              <i class="fas fa-plus"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" v-if="csContables.length > 0 || estado_contable == 1" >
        <table class="table table-bordered table-striped table-hover table-sm p-2">
          <thead class="bg-dark">
            <tr>
              <th class="text-center">Tipo Presupuesto</th>
              <th class="text-center">CECO/AFE/G&A</th>
              <th class="text-center" style="width: 15em;">
                <small>% de la operación para cargar este CECO/AFE</small>
              </th>
              <th style="width: 10em;">Acciones</th>
            </tr>
          </thead>
          <tbody id="tbody">
            <tr v-for="(csContable, index) in csContables" :key="csContable.id">
              <td class="text-center">
                <v-select class="form-control form-control-sm p-0"
                  v-if="id_actualizar_contable == index && estado_contable == 2"
                  :class="[
                    $v.contable.tipo_presupuesto.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="contable.tipoPresupuesto"
                  placeholder="Tipo Presupuesto"
                  label="descripcion"
                  :options="$parent.listasForms.tipo_presupuesto"
                  @input="[
                    resetCecoAfe(),
                    getCecosAfes(),
                  ]"
                ></v-select>
                <span v-else class="badge"
                  :class="
                    csContable.tipo_presupuesto == 1 ? 
                    'bg-info' : 
                    csContable.tipo_presupuesto == 2 ? 
                    'bg-navy' : 
                    'bg-lime'
                  "
                >
                  {{ csContable.tipoPresupuesto ? csContable.tipoPresupuesto.descripcion : null }}
                </span>
              </td>
              <td class="text-center">
                <v-select v-model="ceco_afe"
                  v-if="id_actualizar_contable == index && estado_contable == 2"
                  placeholder="Ceco Afe"
                  label="codigo_contable"
                  :options="listasForms.ceco_afes"
                  class="form-control form-control-sm p-0"
                  :class="
                    $v.contable.ceco_afe_id.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  @input="getSelectCeco()"
                  :disabled="contable.tipo_presupuesto == null"
                ></v-select>
                <v-select v-model="csContable.ceco_afe"
                v-else
                  placeholder="Ceco Afe"
                  label="codigo_contable"
                  :options="listasForms.ceco_afes"
                  class="form-control form-control-sm p-0"
                  disabled
                ></v-select>
              </td>
              <td class="text-center">
                <div v-if="id_actualizar_contable == index && estado_contable == 2">
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    v-model="contable.porcentaje"
                    :class="
                      $v.contable.porcentaje.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                  />
                </div>
                <div v-else>{{ csContable.porcentaje }}</div>
              </td>
              <td>
                <div class="btn-group float-right" v-if="estado_contable != 2">
                  <button
                    type="button"
                    class="btn bg-navy"
                    :disabled="estado_contable != -1"
                    @click="editContable(index)"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                  <button
                    class="btn bg-danger"
                    :disabled="estado_contable != -1"
                    @click="destroyContable(index)"
                  >
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
                <div class="btn-group float-right"
                  v-if="estado_contable == 2 && id_actualizar_contable == index">
                  <button
                    type="button"
                    class="btn bg-success"
                    v-show="!$v.contable.$invalid"
                    @click="saveUpdateContable(index)"
                  >
                    <i class="fas fa-save"></i>
                  </button>
                  <button class="btn bg-danger" @click="cancelContable()">
                    <i class="fas fa-ban"></i>
                  </button>
                </div>
                <div class="btn-group float-right"
                  v-if="estado_contable == 2 && id_actualizar_contable !== index">
                  <button
                    type="button"
                    class="btn bg-navy"
                    disabled
                    @click="editContable(index)"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                  <button
                    class="btn bg-danger"
                    disabled
                    @click="destroyContable(index)"
                  >
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
            <tr v-if="estado_contable == 1">
              <td>
                <v-select class="form-control form-control-sm p-0"
                  :class="[
                    $v.contable.tipo_presupuesto.$invalid ? 'is-invalid' : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="contable.tipoPresupuesto"
                  placeholder="Tipo Presupuesto"
                  label="descripcion"
                  :options="$parent.listasForms.tipo_presupuesto"
                  @input="getCecosAfes()"
                ></v-select>
              </td>
              <td>
                <v-select
                  v-model="ceco_afe"
                  placeholder="Ceco Afe"
                  label="codigo_contable"
                  :options="listasForms.ceco_afes"
                  class="form-control form-control-sm p-0"
                  :class="
                    $v.contable.ceco_afe_id.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  @input="getSelectCeco()"
                  :disabled="contable.tipo_presupuesto == null"
                ></v-select>
                
              </td>
              <td>
                <input
                  type="number"
                  class="form-control form-control-sm"
                  id="porcentaje"
                  v-model="contable.porcentaje"
                  :class="
                    $v.contable.porcentaje.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </td>
              <td>
                <div class="btn-group float-right">
                  <button
                    class="btn bg-success"
                    v-show="!$v.contable.$invalid"
                    @click="saveContable()"
                  >
                    <i class="fas fa-save"></i>
                  </button>
                  <button class="btn bg-danger" @click="cancelContable()">
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "CsServicioPresupuesto",
  components: {
    vSelect,
  },
  data() {
    return {
      ceco_afe: {},
      contable: {},
      asignacionContable: null,
      listasForms: {
        ceco_afes: [],
        asignaciones_contables: [],
      },
      csContables: [],
      totalPresupuesto: 0,
      estado_contable: -1,
      id_actualizar_contable: -1,
    };
  },
  validations: {
    contable: {
      tipo_presupuesto: {
        required,
      },
      ceco_afe_id: {
        required,
      },
      codigo_ceco_afe: {
        required,
      },
      porcentaje: {
        required,
      },
    },
  },

  methods: {
    async getIndex() {
      this.limpiarContable();
      let me = this;
      me.$parent.cargando = true;
      await axios
        .get("/api/cs/serviciosNacionales/contable", {
          params: {
            cs_servicio_nacional_id: me.$parent.servicioNacional.id,
          },
        })
        .then(async (response) => {
          if (response.data.length > 0) {
            me.csContables = await response.data;
            // Se ajusta el resultado para los formularios
            me.csContables.forEach(cont =>{
              cont.tipoPresupuesto = this.$parent.listasForms.tipo_presupuesto.find(e => e.numeracion == cont.tipo_presupuesto);
              cont.porcentaje = parseFloat(cont.porcentaje);
            });
          }
          else if(me.$parent.servicioNacional.det_solicitud) {
            me.$parent.servicioNacional.det_solicitud.solicitud_nacional.solicitudes_presupuestos.forEach(sol => {
              // Se valida el nombre del funcionario 
              if (sol.ceco_afe.funcionario) {
                sol.ceco_afe.funcionario.nombre = sol.ceco_afe.funcionario ? `${sol.ceco_afe.funcionario.nombres} ${sol.ceco_afe.funcionario.apellidos}` : null;
              }
              me.csContables.push({
                tipo_presupuesto: sol.ceco_afe.tipo_presupuesto,
                ceco_afe_id: sol.ceco_afe.id,
                codigo_ceco_afe: sol.ceco_afe.codigo_contable,
                porcentaje: parseFloat(sol.porcentaje),
                cs_servicio_nacional_id: me.$parent.servicioNacional.id,
                tipoPresupuesto: this.$parent.listasForms.tipo_presupuesto.find(e => e.numeracion == sol.tipo_presupuesto),
                ceco_afe: sol.ceco_afe,
              });
            });
          }
          me.getAsignacionesContables();
          me.calcularContable();
          me.$parent.cargando = false;
        });
    },

    async addContable() {
      await this.limpiarContable();
      this.contable.cs_servicio_nacional_id = this.$parent.servicioNacional.id;
      this.estado_contable = 1;
    },

    async saveContable() {
      let me = this;
      await me.calcularContable();
      if (
        me.totalPresupuesto + parseFloat(me.contable.porcentaje) <= 100 &&
        me.contable.porcentaje > 0
      ) {
        me.csContables.push(me.contable);
        me.estado_contable = -1;
        await me.calcularContable();
      } else {
        me.$swal({
          icon: "error",
          title: "Ocurrió un error... El porcentaje no puede ser mayor al 100%",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    getAsignacionesContables() {
      this.listasForms.asignaciones_contables = [];
      axios
        .get("/api/cs/asignacionesContables/lista", {
          params: {
            estado: 1,
          },
        })
        .then((response) => {
          this.listasForms.asignaciones_contables = response.data;
        });
    },

    async getCecosAfes() {
      this.contable.tipo_presupuesto = null;
      if (this.contable.tipoPresupuesto) {
        this.contable.tipo_presupuesto = this.contable.tipoPresupuesto.numeracion;
        await axios
          .get("/api/funcionariosFrontera/wsObjetosActivos/listaCompleta", {
            params: {
              tipo_presupuesto: this.contable.tipoPresupuesto.numeracion,
              linea_negocio: 4,
            },
          })
          .then((response) => {
            this.listasForms.ceco_afes = response.data;
          });
      }
    },

    async cambiarAsignaciones(){
      this.$parent.cargando = true;
      if (this.asignacionContable) {
        await axios
          .get("/api/cs/serviciosNacionales/contable/asignacionesContables", {
            params: {
              cs_asignacion_contable_id: this.asignacionContable.id,
            },
          })
          .then((response) => {
            if (response.data) {
              let arrayAsignacionContable = [];
              response.data.forEach((data) => {
                arrayAsignacionContable.push({
                  id: null,
                  cs_servicio_nacional_id: this.$parent.servicioNacional.id,
                  tipo_presupuesto: data.tipo_presupuesto,
                  ceco_afe_id: data.contrato_contable.id,
                  codigo_ceco_afe: data.contrato_contable.codigo_contable,
                  porcentaje: parseFloat(data.porcentaje),
                  nTipoPresupuesto: data.nTipoPresupuesto,
                  ceco_afe: data.contrato_contable,
                  tipoPresupuesto: this.$parent.listasForms.tipo_presupuesto.find((ceaf) => ceaf.numeracion === data.tipo_presupuesto),
                });
              });
              this.csContables = arrayAsignacionContable;
            }
          });
      }
      this.$parent.cargando = false;
    },

    async saveContableTotal() {
      axios({
        method: "POST",
        url: "/api/cs/serviciosNacionales/contable",
        data: {
          csContables: this.csContables,
          cs_servicio_nacional_id: this.$parent.servicioNacional.id,
        },
      })
        .then(async (response) => {
          await this.getIndex();
          this.guardar++;
        })
        .catch((e) => {
          this.message += e.message;
        });
    },

    async editContable(index) {
      this.limpiarContable();
      this.ceco_afe = this.csContables[index].ceco_afe;
      this.id_actualizar_contable = index;
      this.estado_contable = 2;
      this.contable = { ...this.csContables[index] };
      this.ceco_afe_obj = { ...this.csContables[index].ceco_afe_obj };
      await this.getCecosAfes();
    },

    async saveUpdateContable(index) {
      let me = this;
      this.csContables[index] = this.contable;
      this.estado_contable = -1;
      this.id_actualizar_contable = -1;
      await me.calcularContable();
    },

    async limpiarContable() {
      this.contable = {
        tipo_presupuesto: null,
        ceco_afe_id: null,
        codigo_ceco_afe: null,
        porcentaje: null,
        cs_servicio_nacional_id: null,
        tipoPresupuesto: null,
        ceco_afe: null,
      };
      this.ceco_afe = {};
    },

    resetCecoAfe(){
      this.contable.ceco_afe = null;
      this.contable.ceco_afe_id = null;
      this.ceco_afe = null;
    },

    async cancelContable() {
      this.limpiarContable();
      this.estado_contable = -1;
    },

    async destroyContable(index) {
      this.$swal({
        title: "Quitar Cargo Contable.",
        text: "Está seguro de quitar este cargo contable?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Quitar!",
      }).then((result) => {
        if (result.value) {
          this.csContables.splice(index, 1);
          this.calcularContable();
        }
      });
    },

    async calcularContable() {
      let totalPresupuesto = 0;
      this.csContables.forEach((element) => {
        totalPresupuesto += parseFloat(element.porcentaje);
      });
      this.totalPresupuesto = totalPresupuesto;
      return totalPresupuesto;
    },

    getSelectCeco() {
      this.contable.ceco_afe_id = null;
      if (this.ceco_afe) {
        this.contable.ceco_afe = { ...this.ceco_afe };
        this.contable.ceco_afe_id = this.ceco_afe.id;
        this.contable.codigo_ceco_afe = this.ceco_afe.codigo_contable;
      }
    },
  },

  async mounted() {
    await this.getIndex();
  },
};
</script>
